/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: "Nunito", sans-serif !important;
    line-height: 1.2 !important;
    font-weight: 700 !important;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Nunito", sans-serif;
}

.section {
    padding: 50px 0;
}

p {
    font-size: 17px;
    line-height: 28px;
    font-weight: 400;
    color: #7c8595;
    margin-bottom: 20px;
    font-family: 'Open Sans', sans-serif;
}

.form-control:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, .25) !important;
    border-color: #DC3545 !important;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.box-shadow-class {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, .25) !important;
}

/* Loader */
.loader-body {
    background-color: #15202b;
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

/* Loader style */

.loader {
    width: 48px;
    height: 48px;
    display: block;
    margin: 15px auto;
    position: relative;
    color: #ccc;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    transform: scale(0.5) translate(0, 0);
    background-color: #ccc;
    border-radius: 50%;
    animation: animloader 1s infinite ease-in-out;
}

.loader::before {
    background-color: #DC3545;
    transform: scale(0.5) translate(-48px, -48px);
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animloader {
    50% {
        transform: scale(1) translate(-50%, -50%);
    }
}

/* END Loader style */


.p-dropdown:not(.p-disabled):hover {
    border-color: #DC3545 !important;
}

.p-inputtext:enabled:focus {
    border-color: #DC3545 !important;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.p-overlay {
    z-index: 1060 !important;
}

ol, ul {
    padding: 0 !important;
}